import React, { useState } from 'react';
import MaterialTable from '@material-table/core';
import { tableIcons } from '../shared/MaterialTableShared';
import ToggleOn from '@mui/icons-material/ToggleOn';
import ToggleOff from '@mui/icons-material/ToggleOff';
import IsAdminUser from '@mui/icons-material/VerifiedUser';
import NotAdminUser from '@mui/icons-material/VerifiedUserOutlined';
import Visibility from '@mui/icons-material/Visibility';
import { TablePagination, Tooltip } from '@mui/material';
import StandardModal from '../shared/StandardModal';
import { useDispatch } from 'react-redux';
import { addRemoveRole, userActivation } from '../../store/actions/userActions';
import Loading from '../general/Loading';
import { useSnackbar } from 'notistack';
import { useNavigate  } from 'react-router';
import HttpClient, { objectToQuery } from '../../api/HttpClient';
import { dateFormatted } from '../../shared/utils/date-utils';
import { COLORS } from '../../shared/constants/layout-constants';
import { EditOutlined } from '@mui/icons-material';
import { useSelector } from '../../store';

const client = new HttpClient();
interface IUserTable {
	title: string,
	fixed_query_params: Object
	onEditUser: (user: any) => void
}

const iconStyle = {
	color: COLORS.BRAND_PRIMARY, fontSize: 24, cursor: 'pointer'
}


const UserTable: React.FC<IUserTable> = (props) => {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [processing, setProcessing] = useState(false);
	const [selectedUserData, setSelectedUserData] = useState({} as any)
	const [activationModalOpen, setActivationModalOpen] = useState(false)
	const [userAdminStatusModalOpen, setUserAdminStatusModalOpen] = useState(false);
	const [userTableKey, setUserTableKey] = useState(0);
	const theme = useSelector((state) => state.main.theme);
	const { title } = props;

	const mainStyle = {
		backgroundColor: theme.colors.background,
		color: theme.colors.font,
	}

	const columns = [
		{
			title: 'Id', field: 'id', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'First Name', field: 'first_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Last Name', field: 'last_name', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'HR Low', field: 'hr_low', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'HR High', field: 'hr_high', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Signup Date', field: 'created_at', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			},
			render: (row: any) => {
				return (
					<span>{dateFormatted(row.created_at, "yyyymmdd")}</span>
				)
			}
		},
		{
			title: 'Phone', field: 'phone', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: 'Email', field: 'email', sorting: true, cellStyle: {
				width: 300,
				maxWidth: 300
			}
		},
		{
			title: "User Actions",
			render: (row: any) => {
				return (
					<div style={{ cursor: 'pointer' }}>
						<Tooltip title={"View User"} placement="top">
							<Visibility
								style={iconStyle}
								onClick={() => {
									navigate('/Users/' + row.id)
								}}
							/>
						</Tooltip>
						<Tooltip title={"Edit User"} placement="top">
							<EditOutlined
								style={iconStyle}
								onClick={() => {
									props.onEditUser(row);
								}}
							/>
						</Tooltip>
						{row.active ? <Tooltip title={"Deactivate"} placement="top">
							<ToggleOn
								style={iconStyle}
								onClick={() => {
									setSelectedUserData({
										...row
									})
									setActivationModalOpen(true);
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Activate"} placement="top">
								<ToggleOff
									style={iconStyle}
									onClick={() => {
										setSelectedUserData({
											...row
										})
										setActivationModalOpen(true);
									}}
								/>
							</Tooltip>
						}
						{row.roles?.includes('Admin') ? <Tooltip title={"Demote From Admin"} placement="top">
							<IsAdminUser
								style={iconStyle}
								onClick={() => {
									setSelectedUserData({
										...row
									})
									setUserAdminStatusModalOpen(true);
								}}
							/>
						</Tooltip> :
							<Tooltip title={"Promote to Admin"} placement="top">
								<NotAdminUser
									style={iconStyle}
									onClick={() => {
										setSelectedUserData({
											...row
										})
										setUserAdminStatusModalOpen(true);
									}}
								/>
							</Tooltip>
						}
					</div>
				);
			},
		}
	];

	return (
		<div>
			<Loading loading={processing} />
			<StandardModal
				title={'Activation Modal'}
				open={activationModalOpen}
				onClose={() => setActivationModalOpen(false)}
				paragraphs={[`Would you like to ${selectedUserData.active ? 'deactivate' : 'activate'} ${selectedUserData.email}?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setActivationModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true)
						dispatch(userActivation({ user_id: selectedUserData.id, active: !selectedUserData.active }
							, () => {
								enqueueSnackbar(
									`User active status changed ${selectedUserData.email}.`,
									{ variant: "success" }
								)
								setActivationModalOpen(false)
									;
								setUserTableKey(userTableKey + 1);
								setProcessing(false)
							}, () => {
								enqueueSnackbar(
									`Error changing the active status of user.`,
									{ variant: "error" }
								);
								setProcessing(false)
								setActivationModalOpen(false)
							}))
					}
				}]}
				customActions={[]}
			/>
			<StandardModal
				title={'Alter User Admin Status'}
				open={userAdminStatusModalOpen}
				onClose={() => setUserAdminStatusModalOpen(false)}
				paragraphs={[`Would you like to ${selectedUserData.roles?.includes('Admin') ? 'demote' : 'promote'} ${selectedUserData.email} from Admin Status?`]}
				actions={[{
					title: 'Cancel',
					callback: () => {
						setUserAdminStatusModalOpen(false)
					}
				}, {
					title: 'Yes',
					callback: () => {
						setProcessing(true);
						dispatch(addRemoveRole({ user_id: selectedUserData.id, promote: !selectedUserData.roles?.includes('Admin'), role: 'Admin' }
							, () => {
								setUserAdminStatusModalOpen(false);
								setUserTableKey(userTableKey + 1);
								setProcessing(false);
							}, () => {
								setProcessing(false);
								setUserAdminStatusModalOpen(false)
							}, `User admin status changed ${selectedUserData.email}.`, true))
					}
				}]}
				customActions={[]}
			/>
			<div key={userTableKey}>
			<MaterialTable
				icons={tableIcons}
				title={title}
				columns={columns}
				style={mainStyle}
				data={(query) => new Promise((resolve, reject) => {
					let url = 'api/user/query';

					const params = {
						take: query.pageSize,
						skip: query.page * query.pageSize,
						search: query.search,
						...props.fixed_query_params
					} as any
					if (query.orderBy?.field) {
						params.orderBy = String(query.orderBy.field)
					}
					if (query.orderDirection) {
						params.orderDirection = String(query.orderDirection)
					}
					url += objectToQuery(params);

					(async () => {
						try {
							const result = await client.get(url);
							if (result.data) {
								resolve({
									data: result.data.data,
									page: query.page,
									totalCount: result.data.total,
								});
							} else {
								reject(new Error('Un-Authorized'));
							}
						} catch (error) {
							reject(error);
						}
					})();
				})}
				options={{
					sorting: true,
					search: true,
					pageSize: 15,
					pageSizeOptions: [
						25, 50, 100, 200
					],
					headerStyle: mainStyle,
					searchFieldStyle: mainStyle,
					
				}}
				components={{
					Pagination: props => (
								 <TablePagination
								 {...props}
								style={{...mainStyle, backgroundColor: 'grey'}}
						  />
						),
							  }}
			/>
			</div>
		</div>

	);
};

export default UserTable;
