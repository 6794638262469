import React, { useEffect } from 'react';
import { Chart } from '../../utils/chart';
import { ILineBarChartDataInterface } from './chart-interfaces';

interface ILineGraph {
  chartData: ILineBarChartDataInterface;
  uuid: string;
  title?: string;
  height?: number;
  width?: number;
  minYValue?: number;
  maxYValue?: number;
  stepsY?: number;
  scales?: any;
  isCurrency?: boolean;
  hrLow?: number
  hrHigh?: number
}

const LineGraph: React.FC<ILineGraph> = (props) => {

  useEffect(() => {
    const ctx = document.getElementById(props.uuid);
    // @ts-ignore
    const ctx2 = ctx.getContext('2d');
    const gradient = ctx2.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgb(239,244,251)');
    gradient.addColorStop(1, 'rgb(255,255,255)');

    props.chartData.datasets[0].backgroundColor = gradient
    // @ts-ignore
    const chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: props.chartData.labels,
        datasets: props.chartData.datasets,
      },
      options: {
        maintainAspectRatio: true,
        spanGaps: true,
        plugins: {
          title: {
            display: false,
            text: props.title,
          },
          tooltip: {
            callbacks: {
              title: function (context: any) {
                let title = context[0].label;
                return title
                //let titleWithTimeRemoved = title.split(',').slice(0, 2).join(',');
                //return titleWithTimeRemoved;
              },
              label: function (context: any) {
                console.log("context huh", context)
                let label = context.raw + ' bpm' //context.dataset.label || '';
                return label;
                let yAxis = context.formattedValue;

                if (label) {
                  label += ': ';
                }
                if (props.isCurrency) {
                  yAxis = yAxis.replace(',', '.');
                  label += '$';
                } else {
                  yAxis += ' Items';
                }
                if (context.parsed.y !== null) {
                  label += yAxis;
                }
                return label;
              },
            },
          },
          legend: {
            display: false,
          },
          customCanvasBackgroundColor: {
            color: 'red',
          },
          annotation: {
            annotations: [
              {
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: props.hrLow, // Replace with your specific value
                borderColor: 'blue', // Line color
                borderWidth: 2,
                label: {
                  enabled: true,
                  content: 'HR Low', // Replace with your label
                },
              },
              {
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: props.hrHigh, // Replace with your specific value
                borderColor: 'red', // Line color
                borderWidth: 2,
                label: {
                  enabled: true,
                  content: 'Hr High', // Replace with your label
                },
              },
            ],
          },
        },
        scales: props.scales || {
          x: {
            type: 'time',
            time: {
              unit: 'second', // Adjust this according to your data's time granularity
              displayFormats: {
                day: 'MM/DD/YYYY', // Format for displaying dates
              },
            },
            grid: {
              display: true,
            },
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              maxTicksLimit: 10,
              display: true,
            },
          },
          y: {
            ticks: {
              display: true,
              maxTicksLimit: 10,
            },
            grid: {
              display: false,
            },
          },
        },
        elements: {
          point: {
            radius: 3,
          },
        },
      },
    });

    return () => {
      chartInstance.destroy();
    };
  }, [props]);

  return (
    <div>
      <canvas id={props.uuid} width={props.width || 800} height={props.height || 400} />
    </div>
  );
};

export default LineGraph;