import * as React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { IRootState } from './interfaces/store';
import AppAuthenticated from './AppAuthenticated';
import AppPublic from './AppPublic';
import './App.css';
import ErrorBoundary from './components/shared/ErrorBoundary';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { initSocket } from './store/actions/socketActions';
import { getGlobalSettings } from './store/actions/globalSettingsActions';
import { useSelector } from './store';

function App(props: any) {

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const error = useSelector((state) => state.main.error);
	const success = useSelector((state) => state.main.success);
	const [socketInited, setSocketInited] = useState(false);
	const [dataFetched, setDataFetched] = useState(false);
	const user = useSelector((state) => state.main.my_user);

	// const allState = useSelector((state) => state)
	// useEffect(() => {
	// 	console.log("DEBUG STATE", allState)
	//   }, [allState])


	useEffect(() => {
	  if (error.error){
		enqueueSnackbar(error.error, { variant: 'error' });
	  }
	}, [error])
	useEffect(() => {
	  if (success.message){
		enqueueSnackbar(success.message, { variant: 'success' });
	  }
	}, [success])


	const email = useSelector(
		(state: IRootState) => state.main.my_user?.email
	);

	useEffect(() => {
		if (user?.id && !socketInited) {
			setSocketInited(true)
			console.log("DISPATCH INIT SOCKET")
			// dispatch(initSocket())
		}
	}, [user])

	return (
		<ErrorBoundary>
		<div key={email}>
			{email ? <AppAuthenticated /> : <AppPublic />}
		</div>
		</ErrorBoundary>
	);
}

App.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default App;