
import * as React from 'react';
import { Button, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import UserTable from '../../../components/user/UserTable';
import { downloadUsersReport } from '../../../store/actions/userActions';
import Download from '@mui/icons-material/Download';
import Switch from '@mui/material/Switch';
import { AddCircleOutline } from '@mui/icons-material';
import UpsertUserModal from '../../../components/user/UpsertUserModal';
import { useSelector } from '../../../store';

const upsertUserDefaultValues = {
	first_name: '',
	last_name: '',
	email: '',
	phone: '',
	roles: []
}

function Users(props: any) {


	  
	const [filterAdmins, setFilterAdmins] = useState(false);
	const [showInactive, setShowInactive] = useState(false);
	const [upsertUserModalOpen, setUpsertUserModalOpen] = useState(false);
	const [upsertUserModalMode, setUpsertUserModalMode] = useState<'create' | 'edit'>('create');
	const [upsertUserModalInitialValues, setUpsertUserModalInitialValues] = useState({...upsertUserDefaultValues} as any);
	const [upsertUserModalKey, setUpsertUserModalKey] = useState(0);
	const users_updated = useSelector((state) => state.main.meta?.users?.updated_at || 0);
	const theme = useSelector((state) => state.main.theme);

	const dispatch = useDispatch();

	const downloadUsers = () => {
		dispatch(downloadUsersReport({}))
	}

	const queryParams = {} as any
	if (filterAdmins) {
		queryParams.role = 'Admin'
	}
	if (!showInactive){
		queryParams.active = true
	}

	return <>
		<UpsertUserModal 
			key={upsertUserModalKey}
			mode={upsertUserModalMode} 
			setUpsertUserModalOpen={setUpsertUserModalOpen} 
			open={upsertUserModalOpen} 
			initialValues={upsertUserModalInitialValues} 
			requireRoleToCreate={false} 
			onSuccess={() => {}} 
		/>
		<div style={{ width: '100%', marginBottom: 16, display: 'flex' }}>
			<div style={{ flex: 1, textAlign: 'left' }}>

				<Button onClick={() => {
					setUpsertUserModalKey(upsertUserModalKey + 1);
					setUpsertUserModalOpen(true);
				}} variant='outlined' startIcon={<AddCircleOutline />}>
					Add User
				</Button>
			</div>
			<div style={{ flex: 1, textAlign: 'right' }}>
				<FormControlLabel control={<Switch
					sx={{'& .MuiSwitch-track': {
						backgroundColor: 'grey',
					  },}}
					checked={showInactive}
					onChange={(e) => setShowInactive(e.target.checked)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>} label="Show Inactive" />
				<FormControlLabel control={<Switch
					sx={{'& .MuiSwitch-track': {
						backgroundColor: 'grey',
					  },}}
					checked={filterAdmins}
					onChange={(e) => setFilterAdmins(e.target.checked)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>} label="Filter Admins" />
				<Button onClick={() => {
					downloadUsers()

				}} variant='outlined' startIcon={<Download />}>
					Download
				</Button>

			</div>

		</div>
		<UserTable 
		key={filterAdmins.toString() + showInactive.toString() + users_updated.toString()} 
		title={'Users'} 
		fixed_query_params={queryParams}
		onEditUser={(user) => {
			console.log("FULL USER LOG", user)
			setUpsertUserModalMode('edit');
			setUpsertUserModalOpen(true);
			setUpsertUserModalKey(upsertUserModalKey + 1);
			setUpsertUserModalInitialValues({
				id: user.id,
				first_name: user.first_name,
				last_name: user.last_name,
				hr_low: user.hr_low,
				hr_high: user.hr_high,
				email: user.email,
				phone: user.phone,
				roles: user.roles
			})
		}} 
		/>
	</>
}

export default Users