// import * as ChartJs from 'chart.js';
// import annotationPlugin from 'chartjs-plugin-annotation';



// ChartJs.Chart.register.apply(
//   null,
//   // @ts-ignore
//   Object.values(ChartJs).filter((chartClass) => chartClass.id),
// );

// ChartJs.Chart.register(annotationPlugin);

// export default ChartJs;


import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register all controllers, elements, scales and plugins
Chart.register(...registerables);

// Register the annotation plugin
Chart.register(annotationPlugin);

// Export Chart as a named export
export { Chart };