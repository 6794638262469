import { normalize, schema } from 'normalizr';
import { all, call, put } from 'redux-saga/effects';
import HttpClient from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { GetFriendlyError } from '../../utils/general';
import { roleSchema, userRoleSchema } from './schema';
const client = new HttpClient();

async function getUserRolesByUserAPI(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  return client.get('api/auth/user_roles', data);
}

async function resetPasswordAPI(
  data: any,
): Promise<HttpResponse<{ x: string }>> {
  return client.post('api/auth/reset-password', data);
}


const authSaga = {

  * resetPassword(action: IDispatchAction): Generator {
    try {
      const response = (yield call(resetPasswordAPI, action.payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield put({ type: "RESET_PASSWORD_SUCCESS" });
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    }
    catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  * getUserRolesByUser(action: IDispatchAction): Generator {
    try {
      const response = (yield call(getUserRolesByUserAPI, action.payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        yield all([
          put({
            type: 'GET_ROLES_SUCCESS',
            normalized: normalize(response.data.roles, [roleSchema]),
          }),
          put({
            type: 'GET_USER_ROLES_SUCCESS',
            normalized: normalize(response.data.user_roles, [userRoleSchema])
          }),
        ]);
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    }
    catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  * logout(action: IDispatchAction): Generator {
    try {
      yield put({
        type: 'LOGOUT_SUCCESS',
        data: {}
      })
      if (action.onSuccess) {
        action.onSuccess();
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },
};

export default authSaga;
