export enum ACTIONS {
	ERROR = "ERROR",
	SUCCESS = "SUCCESS",
	SHOW_ERROR = "SHOW_ERROR",
	IS_LOADING = 'IS_LOADING',
	NOT_LOADING = 'NOT_LOADING',
	LOGIN = "LOGIN",
	LOGIN_SUCCESS = "LOGIN_SUCCESS",
	LOGOUT = "LOGOUT",
	REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
	PASSWORD_RESET = 'PASSWORD_RESET',
	SET_CLIENT_OPTIONS = 'SET_CLIENT_OPTIONS',

	//auth
	CONFIRM_EMAIL = 'CONFIRM_EMAIL',

	//user
	GET_USER = 'GET_USER',
	GET_USERS = 'GET_USERS',
	UPDATE_USER = 'UPDATE_USER',
	GET_USER_NOTES = 'GET_USER_NOTES',
	CREATE_USER_NOTE = 'CREATE_USER_NOTE',
	UPDATE_USER_NOTE = 'UPDATE_USER_NOTE',
	DELETE_USER_NOTE = 'DELETE_USER_NOTE',
	USER_ACTIVATION = 'USER_ACTIVATION',
	ADD_REMOVE_ROLE = 'ADD_REMOVE_ROLE',
	GET_ROLES = 'GET_ROLES',
	CREATE_USER = 'CREATE_USER',

	//WORKOUT
	GET_WORKOUT = 'GET_WORKOUT',

	//TIMESTREAM
	TIMESTREAM_GET_METRICS = 'TIMESTREAM_GET_METRICS',

	//REPORTING
	DOWNLOAD_USERS_REPORT = 'DOWNLOAD_USERS_REPORT',
	GET_USER_ROLES = 'GET_USER_ROLES',
	GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA',
	GET_DASHBOARD_DETAIL_DATA = 'GET_DASHBOARD_DETAIL_DATA',
	SET_TIMEZONE = 'SET_TIMEZONE',

	//RESOURCES
	CREATE_UPLOAD_RESOURCE = 'CREATE_UPLOAD_RESOURCE',
	UPLOAD_RESOURCE_FILE = 'UPLOAD_RESOURCE_FILE',
	GET_UPLOAD_URLS = 'GET_UPLOAD_URLS',
	CONVERT_RESOURCES = "CONVERT_RESOURCES",
	GET_MY_PROFILE = "GET_MY_PROFILE",

	//AWS
	INIT_PRESIGNED_URL_MULTIPART_UPLOAD = 'INIT_PRESIGNED_URL_MULTIPART_UPLOAD',
	INIT_PRESIGNED_URL_SIMPLE_UPLOAD = 'INIT_PRESIGNED_URL_SIMPLE_UPLOAD',
	COMPLETE_MULTIPART_UPLOAD = 'COMPLETE_MULTIPART_UPLOAD',
	INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS",
	INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS = "INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS",
	CREATE_UPLOAD_RESOURCE_SUCCESS = "CREATE_UPLOAD_RESOURCE_SUCCESS",
	GET_PRESIGNED_UPLOAD_URLS_SUCCESS = 'GET_PRESIGNED_UPLOAD_URLS_SUCCESS',

	//SOCKET
	INIT_SOCKET = 'INIT_SOCKET',
	DISCONNECT_SOCKET = 'DISCONNECT_SOCKET',
	SYNC_DATA_FROM_SOCKET = 'SYNC_DATA_FROM_SOCKET',
	SOCKET_MESSAGE_ERROR = 'SOCKET_MESSAGE_ERROR',

	//GLOBAL SETTINGS
	GET_GLOBAL_SETTINGS = 'GET_GLOBAL_SETTINGS',
	UPDATE_GLOBAL_SETTINGS = 'UPDATE_GLOBAL_SETTINGS',
}
