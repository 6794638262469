import { all, call, put, take } from 'redux-saga/effects';
import HttpClient from '../../api/HttpClient';
import { ACTIONS } from '../../enums/actions';
import { IDispatchAction } from '../../interfaces/store';
import { HttpResponse } from '../../interfaces/store/api/HttpResponse';
import { ICreateUploadResourceResponse, IResourceFileUploadPayload } from '../../shared/interfaces/model/resource.interfaces';
import { CompletedPart } from '../../shared/interfaces/requests/aws.interface';
import { getFileExtension, getFileParts, getFileType } from '../../shared/utils/file';
import { GetFriendlyError } from '../../shared/utils/gen-utils';
import { initPresignedUrlMultipartUpload, completeMultipartUpload, initPresignedUrlSimpleUpload } from '../actions/awsActions';
import { convertResources, createUploadResource } from '../actions/resourceActions';
import { autoYields } from './schema';
const client = new HttpClient();

async function createUploadResourcesAPI(data: any): Promise<HttpResponse<{ x: string }>> {
  return client.post('api/resource/create-upload-resource', data);
}

const resourceSaga = {
  *createUploadResource(action: IDispatchAction): Generator {
    try {
      const payload = action.payload as any;
      const response = (yield call(createUploadResourcesAPI, payload)) as HttpResponse<any>;
      if (response.status === 200 || response.status === 201) {
        const yields = autoYields(response.data);
        yields.push(
          put({
            type: ACTIONS.CREATE_UPLOAD_RESOURCE_SUCCESS,
            payload: response.data,
          }),
        );
        yield all(yields);
        if (action.onSuccess) {
          action.onSuccess(response.data);
        }
      }
    } catch (e) {
      if (action.onFail) {
        action.onFail();
      }
      const error = e as Error;
      const friendlyError = GetFriendlyError(error.message);
      yield put({ type: ACTIONS.ERROR, message: friendlyError });
    }
  },

  *uploadResourceFile(action: IDispatchAction<any>): Generator {
    if (!action.payload) {
      throw new Error('Payload cannot be empty');
    }

    const parts = action.payload.parts || getFileParts(action.payload.file);
    const extension = getFileExtension(action.payload.file.name);
    //Creating resource.
    yield put(
      createUploadResource({
        name: action.payload.name,
        model: action.payload.model,
        modelId: action.payload.modelId,
        extension,
        type: getFileType(action.payload.file.name),
        parts,
        conversions: action.payload.conversions,
        rootPath: action.payload.rootPath,
        uploadCollectionMethod: action.payload.uploadCollectionMethod,
        filename: action.payload.filename,
        primary: action.payload.primary,
        fileSize: action.payload.fileSize
      }),
    );
    const responseAction = (yield take(ACTIONS.CREATE_UPLOAD_RESOURCE_SUCCESS)) as IDispatchAction<ICreateUploadResourceResponse>;
    const urls = responseAction.payload?.urls;
    // We can upload the file now.
    if (parts > 1) {
      yield put(
        initPresignedUrlMultipartUpload(
          { file: action.payload.file, urls: urls as string[] },
          action.onSuccess,
          action.onFail,
          action.onProgress,
        ),
      );
      const multipartUploadAction = (yield take(ACTIONS.INIT_PRESIGNED_URL_MULTIPART_UPLOAD_SUCCESS)) as IDispatchAction;

      // We complete the multipart upload here.
      const completeMultipartUploadPayload = multipartUploadAction.payload as CompletedPart[];
      const key = 'test/' + action.payload.file.name + '.' + extension; // `${getResourceFullPath(responseAction.payload?.resource as IResource)}`;
      yield put(
        completeMultipartUpload({
          key,
          uploadId: responseAction.payload?.uploadId ?? '',
          parts: completeMultipartUploadPayload,
        }),
      );
    } else {
      yield put(
        initPresignedUrlSimpleUpload(
          { file: action.payload.file, url: urls![0] as string },
          // ,
          // action.onFail,
          !action.payload.conversions ? () => action.onSuccess!(responseAction.payload) : undefined,
          !action.payload.conversions ? action.onFail: undefined,
          action.onProgress,
        ),
      );

      const urlUploadResult = (yield take(ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS)) as IDispatchAction<ICreateUploadResourceResponse>;
      console.log("urlUploadResult?", urlUploadResult)
          console.log("earlier responseAction", responseAction)

      if (action.payload.conversions)
      yield put(
        convertResources({
          id: responseAction.payload?.resource?.id,
          conversions: action.payload.conversions,
          queue: action.payload.queue || false
        },
        action.payload.conversions? () => action.onSuccess!(responseAction.payload) : undefined,
        action.payload.conversions? action.onFail: undefined,
        ),
      );

      //yield take(ACTIONS.INIT_PRESIGNED_URL_SIMPLE_UPLOAD_SUCCESS);
    }
  },
};

export default resourceSaga;
