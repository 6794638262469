
import React from "react";

interface IReleaseNotes {
}

export const release_notes = [
	{
		date: '2023-11-29',
		version: '0.0.4',
		environment: 'beta',
		notes: [
			{
				area: 'General',
				note: 'Initial Release.',
			}
		]
	},
	// {
	// 	date: '2023-02-18',
	// 	version: '0.0.2',
	// 	environment: 'beta',
	// 	notes: [
	// 		{
	// 			area: 'Resources',
	// 			note: 'Added conversion processing with optional redis queue.',
	// 		},
	// 		{
	// 			area: 'Account Page',
	// 			note: 'Added a profile image configured to upload signed urls for s3 compatible storage.',
	// 		},
	// 	]
	// },
	// {
	// 	date: '2023-01-24',
	// 	version: '0.0.1',
	// 	environment: 'beta',
	// 	notes: [
	// 		{
	// 			area: 'General',
	// 			note: 'Template project deployed and customized for application use.',
	// 		},
	// 	]
	// },
]

const getEnvironmentDiv = (env: string) => {
	if (env === 'beta' || env === 'development' || env === 'staging') {
		return <span>{env}</span>
	}
	return null;
}

const ReleaseNotes: React.FC<IReleaseNotes> = (props) => {

	return (
		<div>
			<h2>History</h2>
			<h1>Version: {release_notes[0].version}</h1>
			{release_notes.map((rn, rnIndex) => {
				return (
					<div key={rnIndex}>
						<section>
							<article>
								Version {rn.version} ({getEnvironmentDiv(rn.environment)})
								<br />
								Release Date: {rn.date}
								<ul>
									{rn.notes.map((note, noteIndex) => {
										return (
											<li key={noteIndex}>{note.area}: {note.note}</li>
										)
									})}
								</ul>
							</article>
						</section>
						<hr></hr>
					</div>
				)
			})}
		</div>

	)

}
export default ReleaseNotes