import { isArray } from 'lodash'
import { normalize, schema } from 'normalizr'
import { put } from 'redux-saga/effects'
import { getThemeClasses, getThemeColors } from '../../shared/constants/layout-constants'

export const roleSchema = new schema.Entity('roles')
export const userRoleSchema = new schema.Entity('user_roles')
export const userSchema = new schema.Entity('users')
export const resourceSchema = new schema.Entity('resources')
export const metaSchema = new schema.Entity('meta')
export const globalSettingsSchema = new schema.Entity("global_settings");
export const workoutSchema = new schema.Entity('workouts')
export const postWorkoutSurveySchema = new schema.Entity('post_workout_surveys')

export function autoYields (data: any): any[]{
    const yields = [] as any[]
    const pData = {
      resource: resourceSchema,
      resources: [resourceSchema],
      role: roleSchema,
      roles: [roleSchema],
      user: userSchema,
      users: [userSchema],
      user_role: userRoleSchema,
      user_roles: [userRoleSchema],
      global_settings: [globalSettingsSchema],
      meta: metaSchema,
      metas: [metaSchema],
      workout: workoutSchema,
      workouts: [workoutSchema],
      post_workout_survey: postWorkoutSurveySchema,
      post_workout_surveys: [postWorkoutSurveySchema]
    } as any

    Object.keys(data).forEach((property: string) => {
      console.log("PROPERTY", property);
      if (pData[property]) {
        switch (property) {
          // TODO REVIEW IMPLEMENTATION
          // case 'my_user':
          //   localStorage.setItem("user", JSON.stringify(data[property]));
          //   yields.push(
          //     put({
          //       type: "REDUCE_" + property + "_SUCCESS",
          //       overrides: [
          //        {
          //         key: 'my_user',
          //         value: data[property]
          //        }
          //       ],
          //     })
          //   );
          //   break;
          case 'global_settings':
            console.log("do override", property, data[property])
            yields.push(
              put({
                type: "REDUCE_" + property + "_SUCCESS",
                overrides: [
                 {
                  key: 'global_settings',
                  value: data[property]
                 },
                 {
                  key: 'theme',
                  value: {
                    colors: getThemeColors(data[property].theme),
                    classes: getThemeClasses(data[property].theme)
                  }
                 }
                ],
              })
            );
            break;
          default:
            yields.push(
              put({
                type: "REDUCE_" + property + "_SUCCESS",
                normalized: normalize(data[property], pData[property]),
              })
            );
        }
      }
      else if (property.startsWith('deleted_')){ // check for deleted keys
        const compareString = property.replace('deleted_', '')
        if (pData[compareString]) {
          let deleteIds = data[property];
          let stateKey = '';
          let idAttribute = '';
          if (!isArray(deleteIds)){
            deleteIds = [deleteIds]
          }
          if (isArray(pData[compareString])){
            stateKey = pData[compareString][0]._key
            idAttribute = pData[compareString][0]._idAttribute
          }
          else{
            stateKey = pData[compareString]._key
            idAttribute = pData[compareString]._idAttribute
          }
          deleteIds = deleteIds.map((obj: any) => obj[idAttribute])
          const removeKeys = [{ entities: stateKey, ids: deleteIds }];
          yields.push(put({ type: 'REDUCE_DELETE_' + property + '_SUCCESS', removeKeys, forceStateRefresh: true}));
        }
      }
    });
    return yields
}