
import {isString} from 'lodash';

const isUpperCase = (str: any) => /^[A-Z]*$/.test(str)

export const capFirstLetter = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const safeSum = (a: number, b: number) => {
	let aa = Number(a);
	let bb = Number(b);
	if (isNaN(a)) {
		aa = 0
	}
	if (isNaN(b)) {
		bb = 0
	}
	return aa + bb;
}


export const hasNumberLetter6Length = (str: any) => {
	let hasNumber = false;
	let hasUpper = false;

	for (var i = 0; i < str.length; i++) {
		const char = str.charAt(i)
		const upperCase = isUpperCase(char)
		const isNumber = !isNaN(char)
		if (upperCase) {
			hasUpper = true;
		}
		if (isNumber) {
			hasNumber = true;
		}
	}
	if (str.length >= 6 && hasUpper && hasNumber) {
		return true;
	}
	return false;
}

export const makeSearchObject = (obj: any, include_fields = [] as any[]) => {
	const returnVals = [] as any[];
	for (const [key, value] of Object.entries(obj)) {
		switch (typeof value) {
			case 'string':
			case 'number':
				if (include_fields.length === 0 || include_fields.includes(key)) {
					returnVals.push(value)
				}
				break;
			default:
				break;
		}
	}
	return returnVals.join(' ');
}

export const roundNumberFixed2 = (num: any): number => {
	try {
		return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
	}
	catch {
		return 0
	}
}


export const boolToString = (val: any, trueVal: string, falseVal: string) => {
	let boolval = false;
	if (typeof val === 'boolean') {
		boolval = val;
	} else {
		if (typeof val === 'string') {
			if (val.toLowerCase() === 'true') {
				boolval = true;
			}
		}
	}
	if (boolval === true) {
		return trueVal;
	}
	return falseVal;
};

export const usdFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const getUrlParam = (param: string) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	return urlParams.get(param);
};

export function GetFriendlyError(error: any): string {
	try{
	  if (isString(error)){
		return error as string;
	  }
	}
	catch(e){
	
	}
	return 'Error: Unknown Error'
  }