/* eslint-disable no-param-reassign */
import _, { isObject } from "lodash";
import { IReduceAction } from "../../interfaces/store";
import { IMainState } from "../../interfaces/store/main";
import { autoReduce } from "./shared";
import { normalize } from "normalizr";
import { roleSchema, userRoleSchema } from "../sagas/schema";
import { IUser } from "../../shared/interfaces/model/user.interface";
import { IRole } from "../../shared/interfaces/model/role.interface";
import { IUserRole } from "../../shared/interfaces/model/user_role.interface";
import { ACTIONS } from "../../enums/actions";
import { IResource } from "../../shared/interfaces/model/resource.interfaces";
import { IGlobalSettings } from "../../shared/interfaces/global-settings.interface";
import { ITheme, THEME_NAME, getThemeColors } from "../../shared/constants/layout-constants";
import { IWorkout } from "../../shared/interfaces/workout.interface";
import { IPostWorkoutSurvey } from "../../shared/interfaces/post-workout-survey.interface";

let setUser = {} as any;
let setUserRoles = {} as any;
let setRoles = {} as any;
let existingUser = localStorage.getItem("my_user");
let existingRoles = localStorage.getItem("roles");
let existingUserRoles = localStorage.getItem("user_roles")
let existingTimezone = localStorage.getItem("timezone");

if (!existingTimezone) {
  localStorage.setItem("timezone", "America/Chicago")
  existingTimezone = "America/Chicago"
}

// console.log("local storage user", existingUser);
if (existingUser)
  try {
    existingUser = JSON.parse(existingUser);
    setUser = existingUser;
  } catch (e) { }

if (existingUserRoles) {
  try {
    existingUserRoles = JSON.parse(existingUserRoles)
    setUserRoles = existingUserRoles
  }
  catch (e) { }
}

if (existingRoles) {
  try {
    existingRoles = JSON.parse(existingRoles)
    setRoles = existingRoles
  }
  catch (e) { }
}

export const mainStateObject = {
  client_options: {},
  error: {} as any,
  global_settings: {} as IGlobalSettings,
  jwt_token: '',
  meta: {} as any,
  my_user: setUser as IUser,
  resources: {} as { [key: string ]: IResource },
  roles: setRoles as { [key: string]: IRole },
  success: {} as any,
  timezone: existingTimezone as string,
  user_roles: setUserRoles as { [key: string]: IUserRole },
  users: {} as { [key: string]: IUser },
  theme: {
    colors: getThemeColors(THEME_NAME.LIGHT),
    classes: {
      main: "light-theme",
    }
  } as ITheme,
  workouts: {} as { [key: string]: IWorkout },
  post_workout_surveys: {} as { [key: string]: IPostWorkoutSurvey },
};

const resetState = _.cloneDeep(mainStateObject)

const initialState: IMainState = { ...mainStateObject };

const mainReducer = (
  state: any = initialState,
  action: IReduceAction
): IMainState => {


  if (action && (action.stateKey === "main" || !action.stateKey)) {
    if (action.normalized || action.removeKeys || action.overrides) {
      return autoReduce(action, state);
    }
    switch (action.type) {
      case ACTIONS.ERROR:
        if (isObject(action.payload)) {
          state.error = action.payload;
          return { ...state };
        }
        break;
      case ACTIONS.SUCCESS:
        if (isObject(action.payload)) {
          state.success = action.payload;
          return { ...state };
        }
        break;
      case "GET_USER_NOTE_SUCCESS":
        state.user_notes = action.payload;
        break;

      case ACTIONS.LOGIN_SUCCESS:
        let roles = normalize(action.data.roles, [roleSchema])?.entities?.roles
        let user_roles = normalize(action.data.user_roles, [userRoleSchema])?.entities?.user_roles
        localStorage.setItem("jwt", action.data.token);
        localStorage.setItem(
          "my_user",
          JSON.stringify(action.data.user)
        );
        localStorage.setItem(
          "user_roles",
          JSON.stringify(user_roles)
        );
        localStorage.setItem(
          "roles",
          JSON.stringify(roles)
        );
        state.my_user = action.data.user;
        state.user_roles = user_roles
        state.jwt_token = action.data.token;
        state.roles = roles;
        return { ...state }
      case "LOGOUT_SUCCESS":
        localStorage.removeItem("jwt");
        localStorage.removeItem("my_user");
        localStorage.removeItem("user_roles");
        localStorage.removeItem("roles");
        state.jwt_token = "";
        // eslint-disable-next-line no-restricted-globals
        window.location.href = "/Admin";
        return { ...resetState }
      case "SET_CLIENT_OPTIONS_SUCCESS":
        state.client_options = {
          ...state.client_options,
          ...action.payload,
        };
        break;
      case "UPDATE_TIMEZONE":
        state.timezone = action.payload
        break;
      default:
        return state;
    }
  }
  return state;
};

export default mainReducer;
