import { Chip, Divider, FormHelperText, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IRootState } from '../../interfaces/store';
import { MARGIN_SMALL } from '../../shared/constants/layout-constants';
import { useSelector } from '../../store';
import { createUser, getRoles, updateUser } from '../../store/actions/userActions';
import { validateEmail } from '../../utils/validator';
import CustomAlert from '../general/CustomAlert';
import StandardModal from '../shared/StandardModal';
import InputMask from 'react-input-mask';
import { validPasswordRegEx } from '../../shared/constants/user.constants';
import { forEach } from 'lodash';

const ADMIN_SETS_PASSWORD = true

interface IUpsertUserModal {
  mode: string;
  setUpsertUserModalOpen: (dir: boolean) => void;
  open: boolean;
  initialValues: any;
  requireRoleToCreate: boolean;
  onSuccess: () => void;
}

function validatePhoneNumber(input_str: any) {
  const re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  return re.test(input_str);
}

const validatePassword = (password: string) => {
  const isValid = validPasswordRegEx.test(password)
  return isValid
}

const UpsertUserModal: React.FC<IUpsertUserModal> = (props) => {
  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.first_name) {
      errors.first_name = 'Required';
    }
    if (!values.last_name) {
      errors.last_name = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    }
    if (values.email && !validateEmail(values.email)) {
      errors.email = 'Invalid Email';
    }
    // phone required?
    if (!values.phone) {
      errors.phone = 'Required';
    }
    if (!validatePhoneNumber(values.phone)) {
      errors.phone = 'Invalid Phone Number';
    }
    if (props.mode === 'create' && ADMIN_SETS_PASSWORD){
      if (!values.password){
        errors.password = 'Required'
      }
      if (!values.confirm_password){
        errors.confirm_password = 'Required'
      }
      else if (!validatePassword(values.password)){
        errors.password = 'Invalid Password'
      }
      else{
        if (values.password !== values.confirm_password){
          errors.confirm_password = 'Value must match password'
        }
      }
    }



    return errors;
  };

  const formik = useFormik({
    initialValues: props.initialValues,
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles({}));
  }, []);

  const roles = useSelector((state: IRootState) => state.main.roles);

  const [selectedRoles, setSelectedRoles] = useState(props.initialValues.roles as string[]);

  const closeModal = () => {
    props.setUpsertUserModalOpen(false);
  };

  const handleUpsertCall = () => {
    if (props.mode === 'create') {
      dispatch(
        createUser(
          formik.values,
          () => {
            closeModal();
            if (props.onSuccess) {
                props.onSuccess();
            }
          },
          () => {
          }, 'User created successfully', true

        ),
      );
    } else {
      console.log("ITITIAL VALUES", props.initialValues)
      dispatch(
        updateUser(
          { id: props.initialValues.id, ...formik.values },
          () => {
            closeModal();
            if (props.onSuccess) {
              props.onSuccess();
            }
          },
          () => {
          },  'User updated successfully', true
        ),
      );
    }
  };


  const handleRolesSelect = (selectedValues: string[]) => {

    if (!selectedValues) return;

    const newValues = [] as any[];
    forEach(selectedValues, (role) => {
      const existing = newValues.find((x: string) => x === role);
      if (!existing) {
        newValues.push(role);
      }
    });
    formik.setFieldValue('roles', newValues);
    setSelectedRoles(newValues);
  };

  return (
    <>
      <StandardModal
        title={props.mode === 'create' ? 'Add User' : 'Edit User'}
        open={props.open}
        onClose={() => props.setUpsertUserModalOpen(false)}
        actions={[
          {
            title: props.mode === 'create' ? 'Create' : 'Save',
            callback: () => {
              formik.validateForm(formik.values).then((errors) => {
                if (Object.keys(errors).length === 0) {
                  handleUpsertCall();
                }
              });
            },
          }
        ]}
      >
        <>
          <div style={{ display: 'block' }}>
            {Object.keys(formik.errors).length > 0 ? (
              <CustomAlert
                severity="error"
                title="Please verify fields below"
                message={
                'Marked input fields are required.'
                }
              />
            ) : null}
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.first_name}
                    label="First Name"
                    fullWidth={true}
                    size="small"
                    name="first_name"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.last_name}
                    label="Last Name"
                    fullWidth={true}
                    size="small"
                    name="last_name"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.last_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputMask
                    mask="(999) 999-9999"
                    value={formik.values.phone}
                    disabled={false}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                  <TextField
                    error={!!formik.errors.phone}
                    label="Phone"
                    fullWidth={true}
                    size="small"
                    name="phone"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.phone}
                    variant="outlined"
                  />
                  </InputMask>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.email}
                    label="Email"
                    fullWidth={true}
                    size="small"
                    name="email"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type={'number'}
                    error={!!formik.errors.hr_low}
                    label="HR Low"
                    fullWidth={true}
                    size="small"
                    name="hr_low"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.hr_low}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type={'number'}
                    error={!!formik.errors.hr_high}
                    label="HR High"
                    fullWidth={true}
                    size="small"
                    name="hr_high"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.hr_high}
                    variant="outlined"
                  />
                </Grid>
                {props.mode === 'create' && ADMIN_SETS_PASSWORD && <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.password}
                    label="Password"
                    fullWidth={true}
                    size="small"
                    name="password"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.password}
                    variant="outlined"
                  />
                </Grid>}
                {props.mode === 'create' && ADMIN_SETS_PASSWORD && <Grid item xs={6}>
                  <TextField
                    error={!!formik.errors.confirm_password}
                    label="Confirm Password"
                    fullWidth={true}
                    size="small"
                    name="confirm_password"
                    onChange={formik.handleChange}
                    style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                    value={formik.values.confirm_password}
                    variant="outlined"
                  />
                </Grid>}
                <Grid item xs={12}>
                  <Autocomplete
                    id="role-autocomplete"
                    options={Object.values(roles ?? []).map((x) => x.name)}
                    onChange={(e, newVals: any[]) => handleRolesSelect(newVals)}
                    value={selectedRoles}
                    multiple={true}
                    renderTags={() => null}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          style={{ marginBottom: MARGIN_SMALL, marginTop: MARGIN_SMALL }}
                          inputProps={{ ...params.inputProps, maxLength: 25 }}
                          variant="outlined"
                          label="Role"
                          size="small"
                          error={!!formik.errors.roles}
                        />
                      );
                    }}
                  />
                  {!!formik.values.roles?.length && (
                    <FormHelperText id="component-helper-text">Selected roles will appear below</FormHelperText>
                  )}
                </Grid>

                {!!formik.values.roles?.length && (
                  <>
                    <Grid item xs={12}>
                      <b>Role Name</b>
                    </Grid>

                  </>
                )}
                {!!formik.values.roles?.length && formik.values.roles.map((role: any, roleIndex: number) => {
                  return (
                    <>
                      <Grid item xs={6}>
                        <Chip
                          style={{ marginTop: 4 }}
                          variant='outlined'
                          sx={{ marginLeft: '2px' }}
                          key={roleIndex}
                          label={role}
                          onDelete={() => {
                            const curRoles = formik.values.roles;
                            curRoles.splice(roleIndex, 1);
                            formik.setFieldValue('roles', curRoles);
                          }}
                        />
                      </Grid>

                      {roleIndex !== formik.values.roles.length - 1 ? (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      ) : null}

                    </>
                  );
                })}
              </Grid>
            </form>
          </div>
        </>
      </StandardModal>
    </>
  );
};
export default UpsertUserModal;