import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from '../../../interfaces/store';
import { getUser } from '../../../store/actions/userActions';
import Loading from '../../../components/general/Loading';
import { COLORS, MARGIN_MEDIUM } from '../../../shared/constants/layout-constants';
import WorkoutTable from '../../../components/workout/WorkoutTable';
import { getWorkout } from '../../../store/actions/workoutActions';
import { timestreamGetMetrics } from '../../../store/actions/timestreamActions';
import LineGraph from '../../../components/shared/LineGraph';
import 'chartjs-adapter-date-fns';


function WorkoutViewPage(props: any) {
  const params = useParams<{ workoutId: string, userId: string }>();
  const dispatch = useDispatch();
  
  const [workout, setWorkout] = useState({} as any);
  const [postWorkoutSurvey, setPostWorkoutSurvey] = useState({} as any);
  const [workoutFetched, setWorkoutFetched] = useState(false);
  const [userFetched, setUserFetched] = useState(false);
  const [user, setUser] = useState({} as any);
  const [loading, setLoading] = useState(true);
  const [timestreamData, setTimestreamData] = useState({} as any)
  const navigate = useNavigate();

  const workoutId = Number(params.workoutId)

  useEffect(() => {
    if (!isNaN(workoutId)) {
      dispatch(getWorkout({ id: workoutId }, (data) => {
        setWorkoutFetched(true)
      }));
    }
    if (!isNaN(Number(params.userId))) {
      dispatch(getUser({ user_id: params.userId }, () => setUserFetched(true)));
    }
  }, []);

  const users = useSelector((state: IRootState) => state.main.users);

  useEffect(() => {
    if (userFetched) {
      const matchedUser = Object.values(users).find((x) => x.id === Number(params.userId)) as any;

      if (!matchedUser) {
        navigate('/Users');
      } else {
        setUser(matchedUser);
        setLoading(false);
      }
    }
  }, [users, userFetched]);

  const workouts = useSelector((state: IRootState) => state.main.workouts);
  const postWorkoutSurveys = useSelector((state: IRootState) => Object.values(state.main.post_workout_surveys));

  useEffect(() => {
    if (workoutFetched) {
      console.log("workout fetched???", workouts)
      const matchedWorkout = Object.values(workouts).find((x) => x.id === Number(params.workoutId)) as any;

      if (!matchedWorkout) {
      } else {
        console.log("MATCHED WORKOUT")
        setWorkout(matchedWorkout);
        console.log("POST SURVEYS", postWorkoutSurveys)
        const matchedPostWorkoutSurvey = postWorkoutSurveys.find((x) => x.workout_uuid === matchedWorkout.workout_uuid)
        if (matchedPostWorkoutSurvey){
          setPostWorkoutSurvey(matchedPostWorkoutSurvey)
        }

        setLoading(false);
        dispatch(timestreamGetMetrics({workout_id: matchedWorkout.id}, (data: any) => {
          setTimestreamData(data)
        }))
      }
    }
  }, [workoutId, workouts, workoutFetched]);

  <Loading loading={loading} />;

  if (loading) {
    return <div></div>;
  }
  
  return (
    <>
      {workout && <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <h4>UUID: {workout?.workout_uuid}</h4>
          <div>
          <b>Duration: </b>{workout?.duration}
          </div>
          <div>
          <b>Date: </b>{workout?.created_at}
          </div>

        </Box>


      </Box>}

      {userFetched && timestreamData?.Rows?.length && <div style={{border: '1px solid grey', borderRadius: '8px', maxWidth: 800, marginTop: MARGIN_MEDIUM, backgroundColor: '#F5F5F5'}}>
          <LineGraph
                      key={1}
                      isCurrency
                      chartData={{
                        labels: timestreamData.Rows.map((x: any) => new Date(x.Data?.[4]?.ScalarValue)),
                        datasets: [
                          {
                            label: `Heart Rate`,
                            data: timestreamData.Rows.map((x: any) => Number(x.Data?.[5]?.ScalarValue)),
                            borderColor: '#2c73bc',
                            backgroundColor: '#ecf2fa',
                            fill: false,
                            pointStyle: true,
                            pointHitRadius: 10,
                            pointRadius: 3, // Ensure this is set or not set to 0
                          },
                        ],
                        updatedAt: new Date().toISOString(),
                      }}
                      uuid={'heartRate'}
                      hrHigh={user?.hr_high}
                      hrLow={user?.hr_low}
                    />
        </div>}

        {postWorkoutSurvey?.id && <>
        <h3>Workout Survey</h3>
          <div className="survey-data">
      {Object.entries(postWorkoutSurvey).map(([key, value]) => (
        <div key={key} className="survey-data__item">
          <strong>{key}:</strong> {(value as any).toString()}
        </div>
      ))}
    </div>
        </>}
    </>
  );
}

export default WorkoutViewPage;
